export const testimonials = [
  {
    slug: "testimonial-1",
    name: "Testimonial 1",
    url: "https://twitter.com/ardaarican/status/1235110276568993792",
    size: {
      width: 598,
      height: 98,
    },
  },
  {
    slug: "testimonial-2",
    name: "Testimonial 2",
    url: "https://twitter.com/igruenig/status/1299295013810167808",
    size: {
      width: 598,
      height: 129,
    },
  },
  {
    slug: "testimonial-3",
    name: "Testimonial 3",
    url: "https://twitter.com/p_petruch/status/1305277302658433026",
    size: {
      width: 598,
      height: 147,
    },
  },
  {
    slug: "testimonial-4",
    name: "Testimonial 4",
    url: "https://twitter.com/PeterAG1989/status/1251119270832021506",
    size: {
      width: 598,
      height: 137,
    },
  },
  {
    slug: "testimonial-5",
    name: "Testimonial 5",
    url: "https://twitter.com/StartFitness321/status/1254686909022056448",
    size: {
      width: 598,
      height: 255,
    },
  },
  {
    slug: "testimonial-6",
    name: "Testimonial 6",
    url: "https://twitter.com/kentrh/status/1259038840460914689",
    size: {
      width: 598,
      height: 265,
    },
  },
];
