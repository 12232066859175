import React from "react";
import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import BlurUpImage from "../constant/BlurUpImage";
import VideoWrapper from "../constant/VideoWrapper";
import Carousel, { autoplayPlugin, slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { testimonials as constantTestimonials } from "../../constant/testimonials";
import { mediaUrl } from "../../constant/config";

const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  gridItemVideo: {
    marginTop: theme.spacing(8),
  },
  orangeSection: {
    background: "#f4891e",
    backgroundColor: "#f4891e",
  },
  whiteSection: {
    background: theme.palette.background.default,
    backgroundColor: theme.palette.background.default,
  },
  yellowSection: {
    background: "#eace2a",
    backgroundColor: "#eace2a",
  },
  blueSection: {
    background: "#459fe3",
    backgroundColor: "#459fe3",
  },
  videoWrapper: {
    position: "relative",
    paddingBottom: "50.58%",
    overflow: "hidden",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
  },
  video: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    margin: "0 auto",
  },
  mainTitle: {
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "5rem",
    },
  },
  startNowButton: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.25rem",
      fontWeight: "500",
    },
  },
  footerGrid: {
    [theme.breakpoints.up("md")]: {
      gridItem: {
        flexDirection: "column",
      },
    },
  },
  footerTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  footerLink: {
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const HomePage = props => {
  const classes = useStyles(props);

  const handleStartNow = (e, label) => {
    trackCustomEvent({
      category: "Start Now",
      action: "Click",
      label: label,
      value: 0,
    });

    navigate("https://studio.app-mockup.com");
  };

  return (
    <div className={classes.root}>
      <script src="https://player.vimeo.com/api/player.js"></script>

      <div className={clsx(classes.previewTab, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <Typography className={classes.mainTitle} variant="h2">
                  Where App Screenshots
                </Typography>
                <Typography className={classes.mainTitle} variant="h2">
                  Are Made
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ margin: "10px auto", maxWidth: 600, textAlign: "center" }}>
                <Typography variant="h6">
                  AppMockUp Studio is the online design tool trusted by mobile developers to create the most compelling screenshots for the
                  App Store & Play Store. <strong>No account required. It is fast & free.</strong>
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Top Title")}
              >
                Start Now
              </Button>
            </Grid>

            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={52.66} videoId="470284919" autoplay={true} controls={true} />
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Templates Tab */}
      <div id="templates-tab" className={clsx(classes.orangeSection, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Templates</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ maxWidth: 500 }}>
                <strong>Start with a template.</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ margin: "10px 0px", maxWidth: 600, textAlign: "left" }} variant="h6">
                Bring your app screenshots to life faster than ever. AppMockUp has templates for every type of app. Update device frames,
                layouts, gradients, title and subtitle at the click of a button.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Templates Tab")}
              >
                Start Now
              </Button>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={48.75} videoId="469021880" autoplay={true} controls={true} />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div id="preview-tab" className={clsx(classes.previewTab, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Preview</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ maxWidth: 500 }}>
                <strong>Preview before you export.</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ margin: "10px 0px", maxWidth: 600, textAlign: "left" }} variant="h6">
                See how your screenshots will look on the App Store and Play Store for every device. You can even preview in Dark Mode.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Preview Tab")}
              >
                Start Now
              </Button>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={48.75} videoId="469026080" autoplay={true} controls={true} />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div id="device-one-device-two" className={clsx(classes.yellowSection, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Devices</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ maxWidth: 500 }}>
                <strong>Update the look of your device.</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ margin: "10px 0px", maxWidth: 600, textAlign: "left" }} variant="h6">
                Choose real devices, solid color or clay frames. Resize, rotate and position the device anywhere in the screenshot. You can
                even add two devices.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Device one & Device Two")}
              >
                Start Now
              </Button>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={48.75} videoId="469027124" autoplay={true} controls={true} />
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Layout Picker */}
      <div id="layout-picker" className={clsx(classes.previewTab, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Layouts</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ maxWidth: 500 }}>
                <strong>Get creative with the layout.</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ margin: "10px 0px", maxWidth: 600, textAlign: "left" }} variant="h6">
                There are more than 30 different layout templates you can choose from, with more being added every time.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Layout Picker")}
              >
                Start Now
              </Button>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={48.75} videoId="469030555" autoplay={true} controls={true} />
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Testimonials */}
      <div id="testimonials" className={clsx(classes.blueSection, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
                What people are saying about AppMockUp
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{ width: 520, maxWidth: "90%", margin: "0 auto" }}>
                <Carousel
                  plugins={[
                    "infinite",
                    "fastSwipe",
                    {
                      resolve: autoplayPlugin,
                      options: {
                        interval: 1000,
                      },
                    },
                    {
                      resolve: slidesToShowPlugin,
                      options: {
                        numberOfSlides: 2,
                      },
                    },
                  ]}
                  animationSpeed={500}
                >
                  {constantTestimonials.map(testimonial => {
                    const image = {
                      id: `${testimonial.slug}`,
                      alt: testimonial.name,
                      title: testimonial.name,
                      src: `${mediaUrl}/assets/images/testimonials/${testimonial.slug}.png`,
                      blurSrc: `${mediaUrl}/assets/images/testimonials/${testimonial.slug}-preview-pixelated.png`,
                      size: testimonial.size,
                    };

                    return (
                      <React.Fragment key={image.id}>
                        <BlurUpImage image={image} style={{ border: "3px solid #000000" }} />
                      </React.Fragment>
                    );
                  })}
                </Carousel>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Panoramic Tab */}
      <div id="panoramic-tab" className={clsx(classes.previewTab, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Panoramic Background</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ maxWidth: 500 }}>
                <strong>Add a panoramic for a stunning effect.</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ margin: "10px 0px", maxWidth: 600, textAlign: "left" }} variant="h6">
                Connect all your screenshots with stunning panoramic images. AppMockUp automatically adjusts the panoramic when you add or
                remove a new screenshot or device.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Panoramic Tab")}
              >
                Start Now
              </Button>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={48.75} videoId="469034311" autoplay={true} controls={true} />
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Title & Subtitle */}
      <div id="title-subtitle" className={clsx(classes.orangeSection, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Title & Subtitle</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ maxWidth: 500 }}>
                <strong>Make your title & subtitle shine.</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ margin: "10px 0px", maxWidth: 600, textAlign: "left" }} variant="h6">
                With more options than ever, update the Title and Subtitle to match your design goals.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Title & Subtitle")}
              >
                Start Now
              </Button>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={48.75} videoId="469034867" autoplay={true} controls={true} />
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* Font Picker */}
      <div id="font-picker" className={clsx(classes.previewTab, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Font Picker</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ maxWidth: 500 }}>
                <strong>Captivate potential users with the perfect font.</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ margin: "10px 0px", maxWidth: 600, textAlign: "left" }} variant="h6">
                Choosing a perfect font with the new stunning Font Picker is a pleasure. You can even preview the screenshot before chooing
                any of the 960 Google fonts.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Font Picker")}
              >
                Start Now
              </Button>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={48.75} videoId="469035872" autoplay={true} controls={true} />
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Icon */}
      <div id="icon" className={clsx(classes.yellowSection, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Icon</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ maxWidth: 500 }}>
                <strong>Add an icon or a logo.</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ margin: "10px 0px", maxWidth: 600, textAlign: "left" }} variant="h6">
                Make your screenshots pop by adding an icon. Position it anywhere, make it bigger or add a shadow.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Icon")}
              >
                Start Now
              </Button>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={48.75} videoId="469036403" autoplay={true} controls={true} />
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Background */}
      <div id="background" className={clsx(classes.previewTab, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Background</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ maxWidth: 500 }}>
                <strong>Make your background stand out.</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography style={{ margin: "10px 0px", maxWidth: 600, textAlign: "left" }} variant="h6">
                Set your background to a solid color, gradient, image or even a panoramic, like we showed you{" "}
                <a className={clsx(classes.footerLink, classes.footerTitle)} onClick={() => navigate("/#panoramic-tab")}>
                  <strong>here</strong>
                </a>
                .
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Background")}
              >
                Start Now
              </Button>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={48.75} videoId="469037163" autoplay={true} controls={true} />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={clsx(classes.previewTab, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <Typography className={classes.mainTitle} variant="h2">
                  We definitely have more features coming soon.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "More Features Soon")}
                style={{ fontSize: "2rem", marginTop: 32 }}
              >
                Start Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

HomePage.propTypes = {};

export default HomePage;
