import React from "react";

import Root from "../components/Root";
import HomePage from "../components/Home/HomePage";
import SEO from "../components/seo";

const IndexPage = () => (
  <Root>
    <SEO title="The world's most powerful screenshot builder" />
    <HomePage />
  </Root>
);

export default IndexPage;
